import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useRowSelect } from "react-table";
import { Columns } from "./Columns";
import CopyLinkPopup from "../../all-case-list/case-table/CopyLinkPopup";
import DocumentViewer from "../../all-case-list/case-table/DocumentViewer";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import { fileIcon } from "../../common/FileIcons";
import DeleteDocumentPopup from "../../all-case-list/case-table/DeleteDocumentPopup";
import EditDocumentPopup from "../../all-case-list/case-table/EditDocumentPopup";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/Table.css";

const AllDocumentsTable = ({
	documentResponse,
	categoryOption,
	addDocumentTab,
	setDocumentUpdatedToTrue,
	setDocumentDeletedToTrue,
	changeDocumentViewerState,
	caseList,
	userList,
	folderName,
	title,
	documentProcessed,
	setDocumentProcessed,
	viewProcessedDocs,
}) => {
	const columns = useMemo(() => Columns, []);
	const data = useMemo(() => documentResponse, [documentResponse]);

	const { isAdmin } = useContext(AuthContext);

	const tableHooks = (hooks) => {
		hooks.visibleColumns.push((columns) => {
			const actionColumn = !viewProcessedDocs
				? {
						Header: "Action",
						Cell: ({ row }) => {
							return row?.values?.email_doc_count > 1 ? (
								<span
									style={{
										cursor: "pointer",
										color: "#000fb5",
									}}
									onClick={() => addDocumentTab(row.values)}
								>
									{`${row?.values?.email_doc_count} Documents`}
								</span>
							) : (
								<>
									{isAdmin === "2" ? (
										<DocumentViewer
											tableValues={row.values}
											changeDocumentViewerState={changeDocumentViewerState}
										/>
									) : (
										<>
											<EditDocumentPopup
												tableValues={row.values}
												categoryOption={categoryOption}
												setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
											/>
											<CopyLinkPopup tableValues={row.values} />
											<DocumentViewer
												tableValues={row.values}
												changeDocumentViewerState={changeDocumentViewerState}
											/>
											<DeleteDocumentPopup
												tableValues={row.values}
												setDocumentDeletedToTrue={setDocumentDeletedToTrue}
											/>
										</>
									)}
								</>
							);
						},
				  }
				: null;

			return [...columns, ...(actionColumn ? [actionColumn] : [])];
		});
	};

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		getToggleAllRowsSelectedProps,
	} = useTable(
		{
			columns: columns,
			data: data,
			initialState: {
				hiddenColumns:
					caseList === "scanner"
						? [
								"documentID",
								"favourite",
								"document_desc",
								"bg_color",
								"name",
								"category",
								"case_number",
								"uncategorized_email",
								"uncategorized_from_name",
								"email_doc_count",
								"subfolder_name",
								"user_id",
						  ]
						: caseList === "fax"
						? [
								"documentID",
								"favourite",
								"document_desc",
								"bg_color",
								"name",
								"category",
								"case_number",
								"uncategorized_email",
								"email_doc_count",
								"document_title",
								"badge",
								"user_id",
						  ]
						: [
								"documentID",
								"favourite",
								"document_desc",
								"bg_color",
								"name",
								"category",
								"case_number",
								"uncategorized_email",
								"email_doc_count",
								"document_title",
								"subfolder_name",
								"user_id",
						  ],
			},
		},
		tableHooks,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				const selectionColumn = !viewProcessedDocs
					? {
							id: "selection",
							Cell: ({ row }) => (
								<span
									style={{
										visibility:
											(isAdmin === "1" && caseList !== "all") ||
											caseList === "all"
												? "visible"
												: "hidden",
									}}
								>
									<CheckBox {...row.getToggleRowSelectedProps()} />
								</span>
							),
					  }
					: null;
				return [...(selectionColumn ? [selectionColumn] : []), ...columns];
			});
		}
	);

	const selectedFlatRowsID = selectedFlatRows.map((row) => `''`);

	const selectedRowDocumentID = selectedFlatRows.map(
		(row) => `"${row.original.documentID}"`
	);

	const selectedFlatRowsEmail = selectedFlatRows.map(
		(row) => `'${row.values.uncategorized_email}'`
	);

	const selectedFlatRowsName = selectedFlatRows.map((row) =>
		caseList === "scanner"
			? `'${row.values.document_title}'`
			: `'${row.values.uncategorized_from_name}'`
	);

	const selectedFlatRowsCategory = selectedFlatRows.map((row) => {
		if (caseList === "all") {
			if (row.values.category_id === "Email") {
				return "Email";
			} else if (row.values.category_id === "Fax") {
				return "Fax";
			} else if (row.values.category_id === "Scanner") {
				return "Scanner";
			} else if (row.values.category_id === "serviceDocs") {
				return "serviceDocs";
			} else if (row.values.category_id === "Client Unknown") {
				return "Client Unknown";
			} else {
				return "Others";
			}
		} else {
			return "Others";
		}
	});

	let rowCategoryName;

	if (selectedFlatRowsCategory.includes("Email")) {
		rowCategoryName = "Email";
	}

	if (selectedFlatRowsCategory.includes("Fax")) {
		rowCategoryName = "Fax";
	}

	if (selectedFlatRowsCategory.includes("Scanner")) {
		rowCategoryName = "Scanner";
	}

	if (selectedFlatRowsCategory.includes("serviceDocs")) {
		rowCategoryName = "serviceDocs";
	}

	if (selectedFlatRowsCategory.includes("Client Unknown")) {
		rowCategoryName = "Client Unknown";
	}

	const documentTableDataID = documentResponse.map((row) => "tableData");

	const isEntireTableRowsSelected =
		selectedFlatRowsID.length === documentTableDataID.length;

	const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
		return (
			<div className="bulk-func">
				<CheckBox {...getToggleAllRowsSelectedProps()} />
				<Dropdown
					selectedRows={selectedFlatRowsID}
					isEntireTableRowsSelected={isEntireTableRowsSelected}
					setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
					userList={userList}
					email={selectedFlatRowsEmail}
					category_id={caseList}
					categoryName={rowCategoryName}
					rowCategory={selectedFlatRowsID.includes("")}
					folderName={selectedFlatRowsName}
					selectedRowDocumentID={selectedRowDocumentID}
					documentProcessed={documentProcessed}
					setDocumentProcessed={setDocumentProcessed}
				/>
			</div>
		);
	};

	return (
		<div className="table-content">
			<table
				className={
					caseList !== "fax"
						? "documents-table email-case-list-tables"
						: "documents-table fax-case-list-tables"
				}
				{...getTableProps()}
			>
				<thead className="documents-table-header">
					{headerGroups.map((headerGroup, index) => (
						<tr
							className="documents-table-header-row"
							key={index}
							{...headerGroup.getHeaderGroupProps()}
						>
							{headerGroup.headers.map((column, index) => {
								return (
									<th className="documents-table-header-data" key={index}>
										<span className="document-table-select-row">
											{column.id == "selection" ? (
												<span
													style={{
														visibility:
															(isAdmin === "1" && caseList !== "all") ||
															caseList === "all"
																? "visible"
																: "hidden",
													}}
												>
													<SelectionHeader
														getToggleAllRowsSelectedProps={
															getToggleAllRowsSelectedProps
														}
													/>
												</span>
											) : (
												column.render("Header")
											)}
										</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody className="documents-table-body" {...getTableBodyProps()}>
					{rows.map((row, index) => {
						prepareRow(row);
						return (
							<tr
								key={index}
								className="document-table-body-row"
								{...row.getRowProps()}
							>
								{row.cells.map((cell, index) => {
									switch (cell.column.Header) {
										case "File":
											return (
												<td key={index} className="file-icon">
													<span>{fileIcon[cell.value]}</span>
												</td>
											);

										case "Category":
											return (
												<td key={index}>
													<p
														className="category"
														style={{
															background: `${
																cell.row.values.bg_color || "rgb(203, 190, 196)"
															}`,
														}}
													>
														{cell.value || "Other"}
													</p>
												</td>
											);

										case "Tax Year":
											return (
												<td key={index}>
													{cell.value !== "" ? cell.value : "N/A"}
												</td>
											);

										case "Sub Folder":
											return (
												<td key={index}>
													{cell.value !== null ? cell.value : "N/A"}
												</td>
											);

										case "Case ID":
											return (
												<td key={index}>
													{cell.value !== "" ? cell.value : "N/A"}
												</td>
											);

										case "Uploaded":
											return (
												<td key={index}>
													{cell.value !== "0000-00-00" &&
													cell.value !== "0000-00-00 00:00:00"
														? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
														: "N/A"}
												</td>
											);

										case "Modified By":
											return (
												<td key={index}>
													{cell.value !== null && cell.value !== ""
														? cell.value
														: "N/A"}
												</td>
											);

										default:
											return (
												<td key={index} {...cell.getCellProps()}>
													{cell.render("Cell")}
												</td>
											);
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default AllDocumentsTable;
