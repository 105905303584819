import React, { useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { FaRegUserCircle } from "react-icons/fa";
import { RiSettings5Line } from "react-icons/ri";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logoutUser from "../../api/logout";
import { AuthContext } from "../../context/Auth/AuthContext";
import "../../styles/Dropdown.css";

const Dropdown = () => {
  const [itemClicked, setItemClicked] = useState(false);
  const { instance } = useMsal();

  const { token, dispatch, isAdmin, userName } = useContext(AuthContext);

  const redirect = useNavigate();

  const logoutFn = async () => {
    const data = await logoutUser({ token });
  };

  const handleLogout = async () => {
    logoutFn();
    setItemClicked(!itemClicked);
    dispatch({ type: "LOGOUT" });
    redirect("/login");
    toast.success("Successfully Logged Out", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className={!itemClicked ? "dropdown-content" : "clicked"}>
      <ul className="navbar-dropdown">
        <li>
          <div className="dropdown-links">
            <FaRegUserCircle />
            {userName}
          </div>
        </li>
        <li>
          <div className="dropdown-links">
            <RiSettings5Line />
            {isAdmin === "1"
              ? "Admin"
              : isAdmin === "2"
              ? "Read only"
              : "Non Admin"}
          </div>
        </li>
        <li>
          <div
            onClick={handleLogout}
            className="dropdown-links dropdown-links-logout"
          >
            <MdOutlineLogout />
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Dropdown;
