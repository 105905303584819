import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TbChecklist } from "react-icons/tb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import assignUser from "../../../api/assignUser";
import "../../../styles/AssignUserPopup.css";
import assignUserInsideScan from "../../../api/assignUserInsideScan";
import assignProcessedDoc from "../../../api/assignProcessedDoc";

const rejectCaseFormSchema = yup.object().shape({
  user: yup.string().required("User is required field"),
});

const  ProcessedDocsPopup= ({
  userList,
  isEntireTableRowsSelected,
  changeDropdownVisibilityFunc,
  setDocumentUpdatedToTrue,
  docId,
  email,
  category_id,
  categoryName,
  rowCategory,
  folderName,
  selectedRowDocumentID,
  documentProcessed,
  setDocumentProcessed
}) => {
  const [open, setOpen] = useState(false);
  const { token } = useContext(AuthContext);


  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(rejectCaseFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  console.log(categoryName);

  const onProcessedDoc = async (id) => {
    const assignResponse = await assignProcessedDoc({
      token: token,
      id: selectedRowDocumentID,
    });

    if (assignResponse.code === 200) {
      setDocumentProcessed((prev)=> !prev)
      setOpen(false);
      changeDropdownVisibilityFunc();
      toast.success("Document is processed successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    else{
      setOpen(false);
      changeDropdownVisibilityFunc();
      toast.error("Error while processing the document", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
 

  return (
    <div className="dialog-modal select-all-table-dropdown">
      <div
        onClick={() => {
          handleClickOpen(docId);
        }}
        className="deleteBtn"
      >
        processed service docs
      </div>
  
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            <TbChecklist />
          </div>
          <div className="popup-header-title">Processed Service Document </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to move this document to processed service documents?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            onClickFunc={
              onProcessedDoc
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProcessedDocsPopup;
