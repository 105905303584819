const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return {
        token: action.payload.token,
        userID: action.payload.id,
        userName: action.payload.user_name,
        isAdmin: action.payload.is_admin,
      };
    }

    case "LOGOUT": {
      return {
        token: null,
        userID: null,
        userName: null,
        isAdmin: null,
        phoneNumber: null,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
