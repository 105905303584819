import baseUrl from "../config/baseUrl";

const loginUser = async ({ email, password }) => {
  const formData = new FormData();
  formData.append("user_email", email);
  formData.append("password", password);

  try {
    const response = await fetch(`${baseUrl}/apireact/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default loginUser;
