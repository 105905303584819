import React, { useContext, useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
	MdOutlineArrowBackIos,
	MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "./Table";
import { AuthContext } from "../../../context/Auth/AuthContext";
import requestedDocumentList from "../../../api/requestedDocumentList";
import editDocumentDetails from "../../../api/editDocumentDetails";
import Loading from "../../material-ui/Loading";
import pdfDetails from "../../../api/pdfDetails";
import PdfEditor from "../../pdf-viewer/PdfEditor";
import ButtonUI from "../../material-ui/ButtonUI";
import "../../../styles/CaseDocuments.css";

const AllDocumentsList = ({
	caseList,
	addDocumentTab,
	apiLink,
	folderName,
}) => {
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const [docsCount, setDocsCount] = useState(0);

	const [userList, setUserList] = useState("");
	const [documentUpdated, setDocumentUpdated] = useState(0);
	const [documentDeleted, setDocumentDeleted] = useState(0);
	const [documentProcessed, setDocumentProcessed] = useState(false);
	const [documentViewer, setDocumentViewer] = useState(false);
	const [documentId, setDocumentId] = useState("");
	const [pdfLink, setPdfLink] = useState("");
	const [pdfBase64, setPdfBase64] = useState("");
	const [pdfEditor, setPdfEditor] = useState(false);
	const [docFileType, setDocFileType] = useState("");
	const [pdfViewerTitle, setPdfViewerTitle] = useState("");

	const [docCaseID, setDocCaseID] = useState("");
	const [name, setName] = useState("");
	const [category, setCategory] = useState("");
	const [description, setDescription] = useState("");

	const [documentResponse, setDocumentResponse] = useState([]);
	const [categoryOption, setCategoryOption] = useState([]);

	const [globalSearch, setGlobalSearch] = useState("");

	const [viewProcessedDocs, setViewProcessedDocs] = useState(false);

	let searchValue = globalSearch ? `&global_search=${globalSearch}` : "";

	const { token, userID, isAdmin } = useContext(AuthContext);

	const [api, setApi] = useState(
		`${apiLink}?limit=20&offset=0&isAdmin=1&category_id=${caseList}&assigned=FALSE`
	);

	const limit = 20;
	const offset = currentPage * limit;

	const setDocumentUpdatedToTrue = () => {
		setDocumentUpdated((count) => count + 1);
	};

	const setDocumentDeletedToTrue = () => {
		setDocumentDeleted((count) => count + 1);
	};

	const changeActiveTab = () => {
		setDocumentViewer(false);
		setPdfEditor(false);
	};

	const changeDocumentViewerState = async (
		caseID,
		name,
		category,
		desc,
		id,
		type
	) => {
		setLoading(true);
		const response = await pdfDetails(token, id);
		const data = await response.data;
		if (caseList === "all") {
			setDocCaseID(caseID);
		}
		setName(name);
		setCategory(category);
		setDescription(desc);
		setDocumentId(id);
		setPdfLink(data.document_url);
		setDocFileType(type);
		setPdfBase64(data.pdfBlobData);
		setPdfViewerTitle(name);
		setDocumentViewer(true);
		setLoading(false);
	};

	const documentResponseFunc = async () => {
		if (!documentDeleted) {
			setLoading(true);
		}

		if (viewProcessedDocs) {
			setApi(
				"https://ctaxdmsnewdev.nablasol.net/caselist/filter_proccessed_manually_moved?offset=0&limit=10&isAdmin=1&subCategoryId=processedManuallyMoved"
			);
		}

		const response = await requestedDocumentList({ token, api });

		setPageCount(Math.ceil(response?.docsCount / 20));
		setDocsCount(response?.all_document_email_count);
		setDocumentResponse(response?.data?.length !== 0 ? response?.data : []);
		setUserList(response?.userList);
		setCategoryOption(response?.category);

		if (!documentDeleted) {
			setLoading(false);
		}
	};

	useEffect(() => {
		let apiUrl = `${apiLink}?limit=${limit}&offset=${offset}&isAdmin=1&category_id=${caseList}&assigned=FALSE`;
		if (viewProcessedDocs) {
			apiUrl =
				"https://ctaxdmsnewdev.nablasol.net/caselist/filter_proccessed_manually_moved?offset=0&limit=10&isAdmin=1&subCategoryId=processedManuallyMoved";
		}
		setApi(apiUrl);
	}, [currentPage, viewProcessedDocs, apiLink, caseList, limit, offset]);

	useEffect(() => {
		let apiUrl = `${apiLink}?limit=${limit}&offset=${offset}&isAdmin=1&category_id=${caseList}&assigned=FALSE`;
		if (viewProcessedDocs) {
			apiUrl =
				"https://ctaxdmsnewdev.nablasol.net/caselist/filter_proccessed_manually_moved?offset=0&limit=10&isAdmin=1&subCategoryId=processedManuallyMoved";
		}
		setApi(apiUrl);
	}, [currentPage]);

	useEffect(() => {
		const debounceFn = setTimeout(() => {
			setCurrentPage(0);
			setApi(
				`${apiLink}?limit=${limit}&offset=${offset}${searchValue}&isAdmin=1&category_id=${caseList}&assigned=FALSE`
			);
		}, 2500);

		return () => clearTimeout(debounceFn);
	}, [globalSearch]);

	useEffect(() => {
		documentResponseFunc();
	}, [api, documentUpdated, documentDeleted, documentProcessed]);

	const onGlobalSearchChange = (e) => {
		setGlobalSearch(e.target.value);
	};

	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	const onEditDocumentFormSubmit = async (editDocumentFormData) => {
		const editDocumentFormResponse = await editDocumentDetails({
			token: token,
			user_id: userID,
			case_number: docCaseID,
			document_id: documentId,
			document_desc: description,
			document_title: name,
			category: category,
		});

		if (editDocumentFormResponse.code === 200) {
			setDocumentViewer(false);
			setDocumentUpdatedToTrue();
			toast.success("Document is updated successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<>
			{!documentViewer ? (
				<>
					<div className="case-doc-table-header">
						<TextField
							name="globalSearch"
							label="Search"
							type="text"
							autoComplete="off"
							value={globalSearch}
							onChange={onGlobalSearchChange}
							className="case-doc-textfield"
							variant="outlined"
							InputProps={{
								endAdornment: <AiOutlineSearch />,
							}}
						/>
						{caseList === "serviceDocs" && (
							<span>
								<Switch
									onChange={(e) => setViewProcessedDocs(e.target.checked)}
									id="process-doc"
								/>
								<label htmlFor="process-doc">View Processed document</label>
							</span>
						)}
					</div>
					{!loading ? (
						documentResponse.length !== 0 ? (
							<div className="document__table">
								<Table
									documentResponse={documentResponse}
									categoryOption={categoryOption}
									addDocumentTab={addDocumentTab}
									setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
									setDocumentDeletedToTrue={setDocumentDeletedToTrue}
									changeDocumentViewerState={changeDocumentViewerState}
									caseList={caseList}
									userList={userList}
									folderName={folderName}
									documentProcessed={documentProcessed}
									setDocumentProcessed={setDocumentProcessed}
									viewProcessedDocs={viewProcessedDocs}
								/>
								<div className="case-table-pagination">
									<div className="docs-count">{`${docsCount} Documents`}</div>
									<ReactPaginate
										previousLabel={<MdOutlineArrowBackIos />}
										nextLabel={<MdOutlineArrowForwardIos />}
										pageCount={pageCount}
										onPageChange={handlePageClick}
										forcePage={currentPage}
										containerClassName={"doc-pagination"}
										previousLinkClassName={"doc-pagination-link"}
										nextLinkClassName={"doc-pagination-link"}
										disabledClassName={"doc-pagination-link-disabled"}
										activeClassName={"doc-pagination-link-active"}
									/>
								</div>
							</div>
						) : (
							<h5 className="no-doc-data">No Data Available</h5>
						)
					) : (
						<Loading />
					)}
				</>
			) : (
				<>
					{!loading ? (
						!pdfEditor ? (
							<div className="all-document-pdf-viewer">
								<div className="all-document-pdf-viewer-header">
									<div className="all-document-pdf-viewer-title">
										{pdfViewerTitle}
									</div>
									<div className="all-document-pdf-viewer-icons">
										{docFileType === "pdf" && isAdmin !== "2" && (
											<div
												className="all-document-pdf-viewer-icon"
												onClick={() => setPdfEditor(true)}
											>
												<TbEdit />
											</div>
										)}
										<div
											className="all-document-pdf-viewer-icon"
											onClick={() => setDocumentViewer(false)}
										>
											<RiCloseCircleFill />
										</div>
									</div>
								</div>
								<div className="document-viewer-with-from">
									<div className="iframe-wrapper">
										<iframe
											title={pdfViewerTitle}
											src={pdfLink}
											width="100%"
											height="590"
										></iframe>
									</div>
									<div className="document-viewer-form">
										<TextField
											name="caseId"
											label="Case ID"
											type="text"
											autoComplete="off"
											value={docCaseID}
											onChange={(e) => setDocCaseID(e.target.value)}
											variant="outlined"
											required={category !== "Client Unknown" ? true : false}
											fullWidth
										/>
										<TextField
											name="name"
											label="Name"
											type="text"
											autoComplete="off"
											value={name}
											onChange={(e) => setName(e.target.value)}
											style={{ marginTop: "15px" }}
											variant="outlined"
											required
											fullWidth
										/>
										<FormControl fullWidth required>
											<InputLabel
												id="select-label"
												style={{ marginTop: "15px" }}
											>
												Category
											</InputLabel>
											<Select
												name="category"
												sx={{ marginTop: "15px" }}
												labelId="select-label"
												variant="outlined"
												id="simple-select"
												value={category}
												onChange={(e) => setCategory(e.target.value)}
												label="category"
											>
												{categoryOption.map((option) => (
													<MenuItem key={option} value={option}>
														{option}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<TextField
											name="description"
											label="Description"
											multiline
											minRows={3}
											maxRows={3}
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											variant="outlined"
											style={{ marginTop: "15px" }}
											fullWidth
										/>
										<ButtonUI
											formButton={false}
											loading={loading}
											variant="contained"
											buttonText="Submit"
											style={{
												width: "150px",
												height: "35px",
												boxShadow: "none",
												color: "#fff",
												fontWeight: "500",
												borderRadius: "4px",
												letterSpacing: "0.07rem",
												marginTop: "15px",
											}}
											disabled={
												isAdmin === "2" ||
												(category !== "Client Unknown"
													? docCaseID === "" || name === "" || category === ""
														? true
														: false
													: name === "" || category === ""
													? true
													: false)
											}
											onClickFunc={onEditDocumentFormSubmit}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className="all-document-pdf-editor">
								<div
									onClick={() => setPdfEditor(false)}
									className="all-document-pdf-editor-icon"
									title="Back"
								>
									<IoArrowBackCircleSharp />
								</div>
								<div>
									<PdfEditor
										pdfBase64={pdfBase64}
										documentID={documentId}
										documentTitle={pdfViewerTitle}
										caseNumber={docCaseID}
										changeActiveTab={changeActiveTab}
									/>
								</div>
							</div>
						)
					) : (
						<Loading />
					)}
				</>
			)}
		</>
	);
};

export default AllDocumentsList;
