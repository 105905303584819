import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import Navbar from "../components/common/Navbar";
import CaseListTable from "../components/email-case-list/case-list-table/CaseListTable";
import AllDocumentsList from "../components/all-case-list/case-table/AllDocumentsList";
import baseUrl from "../config/baseUrl";
import "../styles/Documents.css";

// https://ctaxdmsnewdev.nablasol.net/caselist/allcase_related_service_docs?offset=0&limit=10&isAdmin=1

const ServiceCaseList = () => {
	const [tab, setTab] = useState([
		{
			id: uuidv4(),
			documentID: null,
			title: "Service Cases",
			content: (addDocumentTab) => (
				<CaseListTable
					caseList="serviceDocs"
					addDocumentTab={addDocumentTab}
					apiLink={`${baseUrl}/caselist/allcase_related_service_docs`}
					folderName={document.uncategorized_from_name}
				/>
			),
			canClose: false,
		},
	]);
	const [tabValue, setTabValue] = useState(tab[0].id);

	const scrollRef = useRef(null);

	const truncate = (string, n) => {
		return string?.length > n ? string.substr(0, n - 1) + "..." : string;
	};

	const scrollLeft = (scrollOffset) => {
		scrollRef.current.scrollLeft += scrollOffset;
	};

	const scrollRight = (scrollOffset) => {
		scrollRef.current.scrollLeft -= scrollOffset;
	};

	const changeActiveTab = () => {
		setTabValue(tab[0].id);
	};

	const addDocumentTab = (document) => {
		const isDocumentAlreadySelected = tab.find(
			(tab) => tab.documentID === document.documentID
		);

		if (isDocumentAlreadySelected !== undefined) {
			return;
		}

		const findIndex = tab.splice(1, 0, {
			id: uuidv4(),
			documentID: document.documentID,
			caseNo: document.uncategorized_flag,
			title: truncate(document.uncategorized_from_name, 9),
			content: () => {
				return (
					<AllDocumentsList
						caseNo={document.uncategorized_flag}
						caseList="serviceDocs"
						email={document.uncategorized_email}
						categoryName={document.category}
						folderName=""
						assigned="FALSE"
					/>
				);
			},
			canClose: true,
		});

		const filteredTab = tab.filter((value, index) => index !== findIndex);

		setTab(filteredTab);
	};

	const handleChange = (index) => {
		setTabValue(index);
	};

	const closeTab = (e, index) => {
		e.stopPropagation();
		const newTab = tab.filter((tab) => tab.id !== index);
		const allDocumentTabId = newTab[0].id;
		setTab(newTab);
		setTabValue(allDocumentTabId);
	};

	return (
		<div className="document-container">
			<div className="navbar">
				<Navbar />
			</div>
			<div className="document-content">
				<div
					className={tab.length > 7 ? "scrollable-tabs" : "not-scrollable-tabs"}
				>
					{tab.length > 7 ? (
						<div className="scroll-icons" onClick={() => scrollRight(150)}>
							<IoIosArrowDropleftCircle />
						</div>
					) : null}
					<div className="tabs-header" ref={scrollRef}>
						{tab.map((tab, index) => (
							<div
								key={tab.id}
								className={
									tabValue === tab.id
										? "tab-title active-tab-title"
										: "tab-title"
								}
								onClick={() => handleChange(tab.id)}
							>
								<span>{tab.title}</span>
								{tab.canClose ? (
									<button
										className="tab-close-icon"
										onClick={(e) => closeTab(e, tab.id)}
									>
										<AiOutlineCloseCircle />
									</button>
								) : null}
							</div>
						))}
					</div>
					{tab.length > 7 ? (
						<div className="scroll-icons" onClick={() => scrollLeft(150)}>
							<IoIosArrowDroprightCircle />
						</div>
					) : null}
				</div>
				<div className="tab-content">
					{tab.map((tab, index) => {
						return tabValue === tab.id ? (
							<div key={index}>
								{tab.title === "Service Cases"
									? tab.content(addDocumentTab)
									: tab.content()}
							</div>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

export default ServiceCaseList;
